import { combineReducers } from 'redux';
import users from '../reducers/users';
import commonData from '../reducers/commonData';
import { LOGOUT_SUCCESS } from '../actions/types'

const appReducer = combineReducers({
  users,
  commonData,
});


const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer;