

import { PRIVACY_POLICY, TERMS_AND_CONDITIONS} from "../actions/types";

const initialState = {
    privacy_policy:null,
    terms_conditions:null,
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PRIVACY_POLICY:
            return {
                ...state,
                privacy_policy:payload
            }
        case TERMS_AND_CONDITIONS:
            return {
                ...state,
                terms_conditions:payload
            }
        default:
            return state
    }
}