import { createStore, applyMiddleware } from 'redux';
import thunk  from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';


const initialState = {};
const middleware = [thunk];
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;







// import { createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk'
// import { composeWithDevTools } from 'redux-devtools-extension';
// import rootReducer from '../reducers';

// const middleware = [thunk];
// // Redux: Store
// const store = createStore(
//     rootReducer,
//     composeWithDevTools(
//         applyMiddleware(...middleware)
//     )
// );

// // Exports
// export  {
//     store
// }
