import React, { lazy, Suspense } from 'react'
import { Typography } from 'antd';
import { Provider } from "react-redux"
import store from "./store"
import './App.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import 'antd/dist/antd.min.css';
import projectlogo from "./assets/img/logo/familyties.png";
const AppRouter = lazy(() => import('./routes'))

const App = () => {
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh" }}>
            <div style={{ textAlign: "center", margin: "1rem 0" }}>
              <img src={projectlogo} className="suspense_logo" />
            </div>
            <Typography className='suspense_text'> <span style={{ color: "" }}>Loading</span> <span style={{ color: "#EF2F3A" }}>Family Ties</span> Please<span style={{ color: "#EF2F3A" }}> wait....</span></Typography>
          </div>
        }
      >
        <AppRouter />
      </Suspense>
    </Provider>
  );
}
export default App;
