//auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

//after auth
export const IS_COLLAPSED = "IS_COLLAPSED";

export const USER_DETAILS = "USER_DETAILS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// common data 
export const COMMON_DATA = "COMMON_DATA"
export const TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS"
export const PRIVACY_POLICY = "PRIVACY_POLICY"

export const USERS_LIST = "USERS_LIST"
export const OTHER_USER_DATA = "OTHER_USER_DATA"
export const GAS_STATION_LIST = "GAS_STATION_LIST"
export const DASHBOARD_DATA = "DASHBOARD_DATA"
export const APPOINTMENT_LIST = "APPOINTMENT_LIST"
export const UPCOMING_APPOINTMENT_LIST = "UPCOMING_APPOINTMENT_LIST"
export const GAS_STATION_LIST_STATS = "GAS_STATION_LIST_STATS"
